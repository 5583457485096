<app-top-header class="top-bar-container" [bgStyle]="'solid'" screenTitle="Order Details"></app-top-header>
<div>
    <form [formGroup]="submitForm">
        <div class="page-content">
            <h6 class="font-extraBold" *ngIf="!isLoading && !cart?.cartItems?.length"
                style="text-align: center;margin-top: 50px">
                {{'No Items In Your Cart' | translate}}
            </h6>
            <div iknLoader [isActive]='isLoading' class="loader-container"></div>

            <div class="menu-block text-right" *ngIf="!isLoading && cart?.cartItems?.length">
                <!-- 
            <h3 class="text-center">
                {{'Order Details'| translate}}
            </h3>
            <hr class="m-2"> -->
                <div class="cart-item">
                    <div class="d-flex flex-column" [@lightSpeedInOnEnter] *ngFor="let item of cart?.cartItems">
                        <div class="d-flex">
                            <div class="mx-2" style="min-width:60px;width:60px;height:60px;border-radius: 5px;">
                                <img [src]="item?.$item.mainPic" style="width:100%;height:100%;border-radius: 5px" />
                            </div>
                            <div class="flex-grow-1 d-flex flex-column  justify-content-between">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h6>{{item.$item.title | iknLocalized}}</h6>
                                    <h6>
                                        {{item.$total | iknCurrency}}
                                        {{truck?.defaultCountry?.currencyCode | iknLocalized}}
                                    </h6>
                                </div>
                                <div class="d-flex align-items-end justify-content-between">
                                    <div class="d-flex align-items-center justify-content-center border rounded">
                                        <div class="px-2  cursor-pointer" (click)="addMore(item)"
                                            style="width:2rem;height:1.5rem;line-height: 1.5rem;;text-align: center">
                                            <i class="fa fa-plus"></i>
                                        </div>
                                        <div>
                                            <span class="px-2 f-boldest"
                                                style="width:2rem;height:1.5rem;line-height: 1.5rem;text-align: center;">{{item.qty}}</span>
                                        </div>
                                        <div class="px-2 cursor-pointer" (click)="addLess(item)"
                                            style="width:2rem;height:1.5rem;line-height: 1.5rem;;text-align: center">
                                            <i class="fa fa-minus"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6 class="text-info mx-2" [routerLink]="['/item', item.$item.id]">
                                            {{'Edit' | translate}}</h6>
                                        <h6 class="btn btn-outline-danger p-0 px-2" (click)="removeItem(item)">
                                            {{'Remove' | translate}}</h6>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div *ngIf="item.extras && item.extras.length">
                            <h6 class="py-2">{{'Extras' | translate}}</h6>
                        </div>
                        <div *ngFor="let extra of item.extras" class="d-flex">
                            <div class="d-flex w-100">
                                <div class="mx-2" style="width:45px;height:45px;border-radius: 5px">
                                    <img [src]="extra.$extraItem.mainPic || item?.$item.mainPic"
                                        style="width:100%;height:100%;;border-radius: 5px" />
                                </div>
                                <div class="flex-grow-1 d-flex flex-column">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6>{{extra.$extraItem.title  | iknLocalized}}</h6>
                                        <h6 *ngIf="extra.$extraItem.price>0">
                                            {{extra.$extraItem.price * extra.$extraItem.qty | iknCurrency}}
                                            {{truck?.defaultCountry?.currencyCode | iknLocalized}}
                                        </h6>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between ">
                                        <div class="d-flex align-items-center justify-content-between  border rounded">
                                            <div class="px-2" (click)="addMoreExtra(item, extra)">
                                                <h6 class="fa fa-plus  mb-0"></h6>
                                            </div>
                                            <h6 class="px-2 mb-0">{{extra.$extraItem.qty}}</h6>
                                            <div class="px-2" (click)="addLessExtra(item, extra)">
                                                <h6 class="fa fa-minus mb-0"></h6>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="m-auto my-2">
                                                <div class="btn btn-outline-danger p-0 px-2 t-medium">
                                                    {{'Remove' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr class="m-0 my-2" />
                    </div>
                </div>
                <div class="cart-item">
                    <div class="info">
                        <div class="title">
                            <span class='color-primary'>{{'Sub Total'|translate}}</span>
                            <div class="price pull-heading-right font-bold">{{cart.$subTotal | iknCurrency}}
                                {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
                        </div>
                    </div>
                    <div class="margin-top-10"></div>
                    <div class="info" *ngIf="cart.$collectorCharge">
                        <div class="title">
                            <span class='color-primary'>{{'Collector Fees'|translate}}</span>
                            <div class="price pull-heading-right font-bold">{{cart.$collectorCharge | iknCurrency}}
                                {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
                        </div>
                    </div>
                    <div class="info">
                        <div class="title">
                            <span class='color-primary'>{{'Delivery'|translate}}</span>
                            <div class="price pull-heading-right font-bold">{{cart.$deliveryCharge | iknCurrency}}
                                {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
                        </div>
                    </div>
                    <div class="info" *ngIf="cart.$discount">
                        <div class="title">
                            <span class='color-primary'>{{'Discount'|translate}}</span>
                            <div class="price pull-heading-right font-bold">-{{cart.$discount | iknCurrency}}
                                {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
                        </div>
                    </div>
                    <div class="info my-2">
                        <div class="title">
                            <span class='color-primary'>{{'Total'|translate}}</span>
                            <div class="price pull-heading-right font-bold">{{cart.$total  | iknCurrency}}
                                {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
                        </div>
                    </div>
                </div>

                <div class="cart-share f-bold">
                    <div class="d-inline-flex align-items-center justify-content-center btn btn-outline-secondary">
                        <!-- <share-buttons [include]="['copy','whatsapp']" [size]="-6" [url]="generateShareUrl()">
                    </share-buttons> -->



                        <i shareButton="copy" class="far fa-link p-1" [url]="generateShareUrl()"
                            (click)='copyLink()'></i>
                        <div class="mx-2 color-primary" >{{'Share if you care' | translate}}</div>
                        <i shareButton="whatsapp" class="fab fa-whatsapp  p-1" [url]="generateShareUrl()"></i>
                    </div>
                </div>
                <div class='mt-4'>
                    <!-- <div class="mt-2">
                    <h3 class="m-0 w-100">
                        {{'Promo Code' | translate}}
                    </h3>
                    <hr class="m-0 my-2 p-0" />
                </div> -->
                    <div class="input-wrapper d-flex align-items-center justify-content-between">
                        <div class="flex-grow-1">
                            <input type="text" [readonly]="!!cart.orderInfo?.promoCode" [(ngModel)]="promoCode" class=""
                                placeholder="{{'PROMO CODE' | translate}}" formControlName="promoCode">
                        </div>

                        <a class="color-primary" (click)="!isValidatingPromoCode && redeemOrDeletePromoCode()"
                            style="font-size:15px">
                            <div class="lds-ellipsis" *ngIf="isValidatingPromoCode">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <span>
                                {{cart.orderInfo.promoCode ? 'REMOVE' : 'APPLY' | translate}}
                            </span>
                        </a>
                    </div>
                    <div class="font-bold" style="padding:5px 0 0 15px" *ngIf="cart.orderInfo.promoCode">
                        <h6>
                            <strong class='color-primary'>{{'Discount' | translate}}: </strong> {{cart.$discount | iknCurrency}}
                            {{'KWD' | translate}}
                        </h6>
                    </div>
                </div>
                <div class="margin-top-10"></div>
                <div class="cart-shop text-center color-primary font-bold">
                    <a [routerLink]="['/']" class="color-primary">{{'Continue Shopping' | translate}}</a>
                </div>
            </div>
            <!-- <a href="https://api.whatsapp.com/send?phone=965{{truck?.supportPhone}}" class="float" target="_blank">
        <i class="fa fa-whatsapp my-float"></i>
    </a> -->
        </div>

        <div class="page-btn f-boldest t-large" *ngIf="!isLoading && cart?.cartItems?.length" [@slideInUpOnEnter]>
            <a (click)="submitCart()"
                class="btn btn-primary bg-primary btn-cart">{{'PROCEED TO CHECKOUT' | translate}}</a>
        </div>
    </form>
</div>
<app-footer></app-footer>

<div class="ikn-modal">
    <ngx-smart-modal #mSelectServiceType identifier="mSelectServiceType" [dismissable]="true">
        <div class="sevice-type-modal">


            <div class="margin-top-10"></div>
            <h1 class="ikn-modal-header font-extraBold">
                {{'Choose Service Type' | translate}}
            </h1>
            <div class="margin-top-10"></div>
            <div class="text-right p-d-10">

                <div class="ikn-modal-content odrer-type-selector-container">
                    <div class="order-type text-right">
                        <div class="margin-top-10"></div>
                        <input class="radio" [(ngModel)]="serviceType" id="delivery" type="radio" value="delivery">
                        <label for="delivery"> {{'Delivery Service' | translate}}</label>
                        <div class="m-b-5"></div>
                    </div>
                    <div class="order-type text-right">
                        <div class="margin-top-10"></div>
                        <input class="radio" [(ngModel)]="serviceType" id="pickup" type="radio" value="pickup">
                        <label for="pickup">{{'Pickup Service' | translate}}</label>
                        <div class="m-b-5"></div>
                    </div>
                </div>
                <div class="form-group btn-container text-center">
                    <button class="btn btn-success" style="width:100%" type="submit" (click)="submitCart(true)">
                        <span>{{'Confirm' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </ngx-smart-modal>
</div>