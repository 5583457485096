import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { TruckInfo, TruckViewDto, CartItem, MenuItemInfo, MenuItem, CategoryView, AppEventType, CategoryInfo } from '../models';
import { Observable } from 'rxjs';
import { UtilsService } from '../utils.service';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import { Router, ActivatedRoute } from '@angular/router';
import { timeout } from 'q';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ExtraCategoryAlertComponent } from '../extra-category-alert/extra-category-alert.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-menu-items-list',
  templateUrl: './menu-items-list.component.html',
  styleUrls: ['./menu-items-list.component.css']
})
export class MenuItemsListComponent implements OnInit {
  qty: number = 0
  cart: Cart;
  items: MenuItem[] = [];
  searchText: string;
  availableItems: number;
  catId: number;
  category: CategoryView;

  @ViewChild(ExtraCategoryAlertComponent) extraCatAlertSelector: ExtraCategoryAlertComponent;
  constructor(private api: ApiService,
    private activatedRoute: ActivatedRoute
    , private router: Router, public utils: UtilsService, public srvCart: CartService, private translate: TranslateService, private toaster: ToastrService) {
    this.cart = srvCart.getCart();
    activatedRoute.params.subscribe(p => {
      if (p['catId']) {
        this.catId = Number(p['catId']);

        if (this.catId == -100) {
          this.srvCart.setServiceType('catering', 'catering');
        }
        this.api.trackEvent(AppEventType.ViewCategory, Number(this.catId));
      }
    })
  }

  truck?: TruckViewDto
  goBack() {
    this.utils.goBack();
    return;
    if (this.category && this.category.parentCategoryId) {
      this.router.navigateByUrl('/sub-category/' + this.category.parentCategoryId);
    } else {
      this.router.navigateByUrl('/');
    }
  }
  ngOnInit() {
    this.api.viewTruck().subscribe(d => {
      this.truck = d;
      if (!this.utils.isNavigationAllowed(d.truck)) {
        this.router.navigate(['/home']);
        return;
      }

      this.checkCategory().subscribe(d => {
        this.category = d;
        this.items = d.items;
        this.availableItems = d.items.length;
        let _self = this;
        _.forEach(this.items, function (item) {
          var foundItem = _.find(_self.cart.cartItems, function (cartItem) {
            return cartItem.$item.id == item.itemInfo.id
          });
          item.qty = !foundItem ? 0 : foundItem.qty;
        });
        this.setCategoryTitle();
        this.checkExtraCartCat();
        this.utils.fbTrack('PageView');
        this.utils.snapchatTrack('PAGE_VIEW');
      });

    })
  }

  checkCategory() {
    if (this.catId == -1) { // Sold Items Category
      return this.api.getTopSoldItems()
    } else if (this.catId == -2) { // Recently Added Items Category
      return this.api.getTopRecentlyAddedItems()
    } else
      return this.api.viewCategory(this.catId);
  }

  setCategoryTitle() {
    if (this.catId == -1) { // Sold Items Category
      this.category.categoryInfo = { id: -1, title: { en: 'Most Selling', ar: 'الأكثر مبيعاً' } } as CategoryInfo;
    } else if (this.catId == -2) { // Recently Added Items Category
      this.category.categoryInfo = { id: -2, title: { en: 'Recently Added', ar: 'المضافة حديثاً' } } as CategoryInfo;
    }
  }

  addItem(item: MenuItem) {

    var found = _.find(this.cart.cartItems, c => c.$item.id == item.itemInfo.id);
    let oldQty = 0;
    if (found)
      oldQty = found.qty;
    if (!this.srvCart.validateItemToAdd(item, oldQty, 1, item.itemInfo.price))
      return;

    if (found) {
      found.qty += 1;
    } else {
      this.srvCart.addItem(_.assignIn(new CartItem(), {
        $item: item.itemInfo,
        extras: [],
        qty: 1,
        notes: "",
      }));
    }
    if (!item.qty)
      item.qty = 0;
    item.qty += 1;
    this.utils.fbTrack('AddToCart');
    this.utils.snapchatTrack('ADD_CART');
    this.api.trackEvent(AppEventType.AddToCart, item.itemInfo.id, { qty: item.qty });
    this.srvCart.saveCart();
  }

  removeItem(item: MenuItem) {
    var found = _.find(this.cart.cartItems, c => c.$item.id == item.itemInfo.id);
    if (found) {
      found.qty -= 1;
      item.qty -= 1;
      if (found.qty == 0) {
        this.srvCart.removeCartItem(found);
      }
    }
    this.srvCart.saveCart();
  }



  checkOutOfStock(item) {
    return this.srvCart.isOutOfStock(item);
  }

  checkExtraCartCat() {
    if (this.cart.cartItems.length != 0) {
      let foundCat = _.find(this.cart.cartItems, ci => this.getCatId(ci.$item.id) == this.catId);
      if (!foundCat && this.category.categoryInfo.extraDeliveryCharge && this.category.categoryInfo.extraDeliveryCharge != 0)
        setTimeout(() => this.extraCatAlertSelector.selectExtraCatAlert(() => { }));
    }
  }

  getCatId(itemId) {
    var baseCat = this.cart.baseCategory(itemId);
    if (baseCat)
      return baseCat.categoryInfo.id
  }
}
